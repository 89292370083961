import { START_REQUEST, UPDATE_CONFIGURATION, RECEIVE_ERROR, GET_CONFIGURATION, FINISH_REQUEST } from "../actions/types";

const initialState = {
  configurations: {
    booking: {
      paymentNeedToCompleteInMin: ''
    },
    slotDurationInMinute: ''
  },
  isFetching: false,
  isError: false,
};

const configurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false
      });
    case UPDATE_CONFIGURATION:
      return Object.assign({}, state, {
        configurationData: action.data,
        isFetching: false,
        isError: false
      });
    case GET_CONFIGURATION:
      return Object.assign({}, state, {
        configurations: action.data,
        isFetching: false,
        isError: false
      });
    case RECEIVE_ERROR:
      return Object.assign({}, state, {
        isError: true,
        isFetching: false
      });
    case FINISH_REQUEST:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
};

export default configurationReducer;