/**
 * Actions for Fetch Store
 */

export const START_REQUEST = "START_REQUEST";
export const FINISH_REQUEST = "FINISH_REQUEST";
export const SAVE_DATA = "SAVE_DATA";
export const RECEIVE_ERROR = "RECEIVE_ERROR";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";


export const CREATE_DOCTOR = "CREATE_DOCTOR";
export const DOCTOR_DETAILS = "DOCTOR_DETAILS"
export const GET_ALL_DOCTORS = "GET_ALL_DOCTORS";
export const GET_ALL_SPECIALITIES = "GET_ALL_SPECIALITIES";

export const GET_ALL_PATIENTS = "GET_ALL_PATIENTS";
export const SAVE_PATIENT = "SAVE_PATIENT";

export const GET_ALL_APPOINTMENTS = "GET_ALL_APPOINTMENTS";
export const SAVE_APPOINTMENT = "SAVE_APPOINTMENT";
export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";
export const GET_CONFIGURATION = "GET_CONFIGURATION";
export const UPDATE_CONFIGURATION = "UPDATE_CONFIGURATION";

export const GET_BRANDS = "GET_BRANDS";
export const ADD_BRANDS = "ADD_BRANDS";
export const UPDATE_BRAND = "UPDATE_BRAND";
export const ACTIVATE_BRAND = "ACTIVATE_BRAND";
export const GET_SINGLE_BRAND = "GET_SINGLE_BRAND";
export const DELETE_BRAND = "DELETE_BRAND";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_REQUESTS = "GET_REQUESTS";
export const UPDATE_REQUESTS = "UPDATE_REQUESTS";

export const GET_SPECIALITIES_LIST = "GET_SPECIALITIES_LIST";
export const UPDATE_SEPCIALITY = "UPDATE_SEPCIALITY";

export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";

export const GET_ALL_TNC = "GET_ALL_TNC";

export const GET_ALL_FEEDBACK = "GET_ALL_FEEDBACK";
