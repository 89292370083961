import { routePath } from "./constants/routes";

export const NAVIGATION = {
  Admin: {
    items: [
      {
        name: "Configuration",
        url: routePath.CONFIGURATION,
        icon: "icon-star",
      },
      {
        name: "Brand",
        url: routePath.BRANDING,
        icon: "icon-star",
      },
      {
        name: "Specialities",
        url: routePath.SPECIALITIES,
        icon: "icon-star",
      },
      {
        name: "Terms and Conditions",
        url: routePath.TERM_AND_CONDITION,
        icon: "icon-star",
      },
      {
        name: "Countries",
        url: routePath.COUNTRIES,
        icon: "icon-star"
      },
      {
        name: "FHIR Import",
        url: routePath.FHIR_IMPORTS,
        icon: "icon-star"
      },
      {
        name: "User's Feedback",
        url: routePath.FEEDBACK,
        icon: "icon-star"
      },
      {
        name: "Delete Requests",
        url: routePath.DELETE_REQUEST,
        icon: "icon-star"
      }
    ],
  },
  RCMExecutive: {
    items: [
      {
        name: "Appointments",
        url: routePath.APPOINTMENTS,
        icon: "icon-star",
      },
      {
        name: "Patients",
        url: routePath.PATIENTS,
        icon: "icon-user",
      },
    ],
  },
  DoctorAssistant: {
    items: [
      {
        name: "Doctors",
        url: routePath.DOCTORS,
        icon: "icon-user",
      },
    ],
  },
};
