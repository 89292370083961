import {
  START_REQUEST,
  FINISH_REQUEST,
  GET_ALL_DOCTORS,
  RECEIVE_ERROR,
  GET_ALL_SPECIALITIES,
  DOCTOR_DETAILS
} from "../actions/types";

const initialState = {
  doctors: [],
  specialities: [],
  doctorData: {},
  totalDoctors: "",
  isFetching: false,
  isError: false,
  error: "",
};

const doctorReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        error: "",
      });

    case GET_ALL_DOCTORS:
      return Object.assign({}, state, {
        doctors: action.data.data,
        totalDoctors: action.data.total,
        isFetching: false,
        isError: false,
      });
    case DOCTOR_DETAILS:
      return Object.assign({}, state, {
        doctorData: action.data,
        isFetching: false,
        isError: false,
      });

    case GET_ALL_SPECIALITIES:
      return Object.assign({}, state, {
        specialities: action.data,
        isFetching: false,
        isError: false,
      });
    case RECEIVE_ERROR:
      return Object.assign({}, state, {
        isError: true,
        isFetching: false,
        error: action.data,
      });
    case FINISH_REQUEST:
      return Object.assign({}, state, {
        isFetching: false,
      });

    default:
      return state;
  }
};

export default doctorReducer;
