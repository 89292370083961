import { START_REQUEST, GET_ALL_PATIENTS, SAVE_PATIENT, RECEIVE_ERROR, FINISH_REQUEST } from "../actions/types";

const initialState = {
  patients: [],
  totalPatients: '',
  isFetching: false,
  isError: false,
  error: ''
};

const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false
      });
    case GET_ALL_PATIENTS:
      return Object.assign({}, state, {
        patients: action.data.data,
        totalPatients: action.data.total,
        isFetching: false,
        isError: false
      });
    case SAVE_PATIENT:
      return Object.assign({}, state, {
        patientData: action.data,
        isFetching: false,
        isError: false
      });
    case RECEIVE_ERROR:
      return Object.assign({}, state, {
        isError: true,
        isFetching: false,
        error: action.data
      });
    case FINISH_REQUEST:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
};

export default patientReducer;