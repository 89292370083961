import {
  START_REQUEST,
  FINISH_REQUEST,
  RECEIVE_ERROR,
  GET_REQUESTS,
} from "../actions/types";

const initialState = {
  isFetching: false,
  isError: false,
  Error: "",
  userRequests: [],
};

const requestReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        Error: ""
      });
    case GET_REQUESTS:
      return Object.assign({}, state, {
        userRequests: action.data.data,
        total: action.data.total,
        isFetching: false,
        isError: false,
        Error: ""
      });
    case RECEIVE_ERROR:
      return Object.assign({}, state, {
        isError: true,
        isFetching: false,
        Error: action.data
      });
    case FINISH_REQUEST:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
};

export default requestReducer;
