import { combineReducers } from "redux";
import doctorReducer from "./doctorReducer";
import appointmentReducer from "./appointmentReducer";
import patientReducer from "./patientReducer";
import configurationReducer from "./configurationReducer";
import brandingReducer from "./brandingReducer";
import notificationReducer from "./notificationReducer";
import loginReducer from "./loginReducer";
import specialityReducer from "./specialityReducer";
import termsAndConditionReducer from "./termsAndConditionReducer";
import countryReducer from "./countriesReducer";
import feedbackReducer from "./feedback";
import requestReducer from './requestReducer'
import { LOGOUT } from "../actions/types";

const allReducers = combineReducers({
  Doctors: doctorReducer,
  Appointments: appointmentReducer,
  Patients: patientReducer,
  Configurations: configurationReducer,
  Brandings: brandingReducer,
  Notifications: notificationReducer,
  Login: loginReducer,
  Speciality: specialityReducer,
  TNC: termsAndConditionReducer,
  Countries: countryReducer,
  FeedBack: feedbackReducer,
  Request: requestReducer
});

const Reducer = (state, action) => {

  if (action.type === LOGOUT)
    state = undefined;

  return allReducers(state, action);
}
export default Reducer;

