export const API = {
  BACKEND: process.env.REACT_APP_backend,
  VERSION: "v1",

  LOGIN_USER: "auth/dashboard-login",
  SAVE_FCM_TOKENS: "firebase/register-notifications",
  CLEAR_FCM_TOKENS: "auth/register-notifications",

  GET_PATIENT_LIST: "rcm/patient-list",
  GET_ALL_APPOINTMENTS: "rcm/appointment-list",
  UPDATE_PAYMENT: "rcm/payment-amount",
  PAYMENT_DETAIL: "rcm/payment-details",

  CREATE_DOCTOR: "doctor-assistant/create-doctor",
  GET_DOCTOR_LIST: "doctor-assistant/get-doctors",
  GET_DOCTOR_DETAILS: "doctor-assistant/get-info",
  UPDATE_DOCTOR_DETAILS: "doctor-assistant/update-doctor-info",
  CREATE_MULTIPLE_SLOTS: "doctor-assistant/create-slots",
  GET_SLOTS: "doctor-assistant/get-slots",
  CREATE_SINGLE_SLOT: "doctor-assistant/create-slot",
  DELETE_SLOT: "doctor-assistant/delete-slot",

  UPDATE_CONFIGURATION: "admin/configuration",
  GET_CONFIGURATION: "admin/configuration",
  GET_BRANDS: "admin/brand-list",
  ADD_BRAND: "admin/add-brand",
  UPDATE_BRAND: "admin/update-brand",
  ACTIVATE_BRAND: "admin/activate-brand",
  GET_SINGLE_BRAND: "admin/get-brand",
  DELETE_BRAND: "admin/delete-brand",
  GET_SPECIALTIES_LIST: "admin/specialities-list",
  UPDATE_SPECIALITIES: "admin/activate-specialities",
  GET_TNC: "admin/get-terms",
  CREATE_TNC: "admin/create-terms",
  UPDATE_TNC: "admin/update-terms",
  GET_COUNTRY_LIST: "admin/get-countries",
  DEACTIVATE_COUNTRY: "admin/update-country/:countryId",
  CREATE_COUNTRY: "admin/add-country",

  GET_ALL_FEEDBACK: "users/feedback",
  GET_USER_CONFIGURATION: "users/configuration",
  GET_SPECIALTIES: "users/get-specialties",
  GET_NOTIFICATIONS: "users/notification?page=1&pageSize=1000",
  MARK_READ_NOTIFICATION: "users/notification/mark-read",

  IMPORT_PATIENTS: "admin/fhir/import-patient",
  IMPORT_PRACTITIONER: "admin/fhir/import-practitioner",

  DIALCODES: "brand/get-countries",

  GET_DELETE_REQUESTS: "admin/get-delete-requests-list",
  UPDATE_DELETE_REQUESTS: "admin/update-delete-request-status"

};
