export const routePath = {
  CONFIGURATION: "/configuration",
  BRANDING: "/brand",
  CREATE_BRAND: "/brand/create",
  GET_BRAND_BY_ID: "/brand/:id",
  SPECIALITIES: "/specialities",
  PATIENTS: "/patients",
  GET_PATIENT_BY_ID: "/patients/:id",
  APPOINTMENTS: "/appointments",
  GET_APPOINYMENT_BY_ID: "/appointments/:id",
  DOCTORS: "/doctors",
  EDIT_DOCTOR: "/doctors/create",
  CREATE_DOCTOR: "/doctors/create",
  GET_DOCTOR_BY_ID: "/doctors/:id",
  LOGIN: "/login",
  TERM_AND_CONDITION: "/terms-and-conditions",
  TNC: "/terms-and-conditions/:id",
  CREATE_TNC: "/terms-and-conditions/create",
  COUNTRIES: "/countries",
  CREATE_COUNTRY: "/countries/create",
  FHIR_IMPORTS: "/fhir-imports",
  EDIT_COUNTRY: "/countries/edit/:id",
  FEEDBACK: "/feedback",
  DELETE_REQUEST: '/delete-user-request',
  VIEW_DELETE_REQUEST: '/delete-user-request/:id'
};
