import {
  START_REQUEST,
  FINISH_REQUEST,
  RECEIVE_ERROR,
  GET_BRANDS,
  UPDATE_BRAND,
} from "../actions/types";

const initialState = {
  brandingData: {},
  isFetching: false,
  isError: false,
  Error: "",
  brandings: [],
};

const brandingReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        Error: ""
      });
    case GET_BRANDS:
      return Object.assign({}, state, {
        brandings: action.data,
        isFetching: false,
        isError: false,
        Error: ""
      });
    case UPDATE_BRAND:
      return Object.assign({}, state, {
        brandingData: action.data,
        isFetching: false,
        isError: false,
        Error: ""
      });
    case RECEIVE_ERROR:
      return Object.assign({}, state, {
        isError: true,
        isFetching: false,
        Error: action.data
      });
    case FINISH_REQUEST:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
};

export default brandingReducer;
