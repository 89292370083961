import {
  START_REQUEST,
  FINISH_REQUEST,
  RECEIVE_ERROR,
  GET_COUNTRIES,
} from "../actions/types";

const initialState = {
  isFetching: false,
  isError: false,
  Error: "",
  countries: [],
};

const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        Error: ""
      });
    case GET_COUNTRIES:
      return Object.assign({}, state, {
        countries: action.data,
        isFetching: false,
        isError: false,
        Error: ""
      });
    case RECEIVE_ERROR:
      return Object.assign({}, state, {
        isError: true,
        isFetching: false,
        Error: action.data
      });
    case FINISH_REQUEST:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
};

export default countryReducer;
