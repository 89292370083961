import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from "./store";
import { Provider } from "react-redux";
import firebase from './config';
import { registerServiceWorker } from "./serviceWorker";
import Loader from "./views/Loader/Loader";

ReactDOM.render(
  <Provider store={store}>
    <Loader />
    <App />
  </Provider>, document.getElementById('root')
);

registerServiceWorker();
