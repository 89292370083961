import { useEffect, useState } from "react";
import moment from "moment";
import { UserRole } from "../constants/strings";
import { NAVIGATION } from "../_nav";
import { PAGE_SIZE } from "../constants/globalConstants";
import store from "../store";
import { LOGOUT } from "../actions/types";
import { clearFcmToken } from "../actions/login";
import { startRequest, receiveError } from "../actions/index";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const clear = () => {
  localStorage.clear();
  store.dispatch(startRequest(LOGOUT));
};

export const logout = async () => {
  const isRCM = getUserRole() === "RCMExecutive";
  if (!isRCM) clear();
  else {
    const body = {
      userId: JSON.parse(localStorage.getItem('user')).id,
      fcmToken: localStorage.getItem('fcm'),
      platform: "web"
    };
    const response = await store.dispatch(clearFcmToken(body));
    if (response && response.successData) {
      clear();
    }
  }
};

export const tokenExpireError = async () => {
  await logout();
  store.dispatch(receiveError("Token expired!!"));
};

export const tokenExpire = (error) => {
  const {
    response,
  } = error;
  if (response) {
    if (response.status === 401) {
      tokenExpireError();
    }
    else {
      return Promise.reject(error);
    }
  }
}

export const getUserRole = () => {
  if (localStorage.getItem("user")) {
    return UserRole[JSON.parse(localStorage.getItem("user")).role];
  }
};

export const imageDownload = () => {
  return window.addEventListener('contextmenu', event => {
    if (event.target.nodeName === "IMG") {
      event.preventDefault();
    }
  }, false);
}

export const convertDate = (date) => moment(date).format("hh:mm A DD-MM-YYYY");

export const convertToDate = (time, format) => moment(time).format(format);

export const getRedirectLink = () =>
  getUserRole() && NAVIGATION[getUserRole()].items[0].url;

export const getUrlPagination = (pageNo) =>
  `?page=${pageNo}&pageSize=${PAGE_SIZE}`;

export const converTimeToHourFormat = (time) => moment(time).format("hh:mm A");

export const getAgeByDOB = (dob) => moment().diff(dob, "years");

export const disappear = (toggle) => {
  setTimeout(toggle, 5000);
};

export const objectToQuery = (params) =>
  Object.keys(params)
    .map((key) => {
      return params[key] && key + "=" + params[key];
    })
    .join("&");

export const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  const parsedToken = JSON.parse(jsonPayload);
  return checkJWTExpire(parsedToken.exp * 1000);
};

export const checkJWTExpire = (time) => time < new Date().getTime();

export const checkPastDate = (date1, date2) => new Date(date1) <= new Date(date2);

export const checkDate = (date1, date2, date3) => new Date(date1) >= new Date(date2) && new Date(date1) <= new Date(date3);

