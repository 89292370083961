import firebase from 'firebase/app';
import 'firebase/messaging';
import swal from 'sweetalert';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory({ forceRefresh: true });


const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseUrl,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId
};
firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();
messaging.usePublicVapidKey(process.env.REACT_APP_publicKey);

export const requestPermission = (saveFcmToken) => {
  messaging.requestPermission()
    .then(function () {
      return messaging.getToken()
    }).then(function (token) {
      localStorage.setItem('fcm', token);
      const body = {
        userId: JSON.parse(localStorage.getItem('user')).id,
        fcmToken: token,
        platform: "web"

      };
      return saveFcmToken(body);
    }).catch(function (err) {
      console.log(err.message);
    });
}

messaging.onMessage(payload => {
  const { title, body, appointmentId } = payload.data;
  swal(title, body, {
    buttons: {
      view: {
        text: "View Details",
        value: "view details",
        className: "btn btn-success mr-1"
      },
      ok: {
        text: "Ok",
        value: "ok",
        className: "btn btn-info mr-1"

      }
    }
  }).then((value) => {
    switch (value) {
      case "view details": history.push(`/appointments/${appointmentId}`);
      case "ok": history.push(window.location.pathname)
    }
  })

});

export default firebase;
