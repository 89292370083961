import { START_REQUEST, RECEIVE_ERROR, GET_ALL_TNC, FINISH_REQUEST } from "../actions/types";

const initialState = {
  tncArray: [],
  totalTnC: "",
  isFetching: false,
  isError: false,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
      });
    case GET_ALL_TNC:
      return Object.assign({}, state, {
        tncArray: action.data.data,
        totalTnC: action.data.total,
        isFetching: false,
        isError: false,
      });
    case RECEIVE_ERROR:
      return Object.assign({}, state, {
        isError: true,
        isFetching: false,
      });
    case FINISH_REQUEST:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
};

export default notificationReducer;
