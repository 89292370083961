import {
    START_REQUEST,
    FINISH_REQUEST,
    RECEIVE_ERROR,
    GET_ALL_FEEDBACK
} from "../actions/types";

const initialState = {
    feedBack: [],
    isFetching: false,
    isError: false,
    error: ""
}

const feedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case START_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                isError: false,
                error: "",
            });
        case GET_ALL_FEEDBACK:
            return Object.assign({}, state, {
                feedBack: action.data
            });
        case RECEIVE_ERROR:
            return Object.assign({}, state, {
                isError: true,
                isFetching: false,
                error: action.data,
            });
        case FINISH_REQUEST:
            return Object.assign({}, state, {
                isFetching: false,
            });

        default:
            return state;
    }
}
export default feedbackReducer;