export const strings = {
  HEADER_TEXT: "Tele Consulting Dashboard",
  SIGNIN_TEXT: "Sign In to your account",
  LOGIN_TEXT: "Login",
  DOCTOR_ROLE: "User Role",
  FORGOT_PASSWORD: "Forgot password?",
  NO_NOTIFICATION: "No New Notification",
  FEEDBACK_TEXT: "Feedback",
  NO_DATA: "No Data to Show"
};

export const DoctorHeadings = [
  "Name",
  "Mobile No.",
  "Age",
  "Email",
  "Specialization",
  "No Of Appointments",
  "Action",
];
export const PatientHeadings = ["Name", "DOB", "Email", "Emirates Id"];
export const AppointmentHeadings = [
  "Patient Name",
  "Doctor Name",
  "Payment Status",
  "Booking Time",
  "Payment Amount",
  "Appointment Status",
  "Appointment Fee",
  "Action",
];
export const PatientDetailHeadings = [
  "Email",
  "Name",
  "Date Of Birth",
  "Emirates Id",
  "Emirates Document",
  "Insurance  Document",
];
export const DoctorsDetailHeadings = [
  "Doctor Id",
  "Name",
  "ConsultationFee",
  "Years Of Experience",
  "Mobile Number",
  "Date of Birth",
  "Email",
  "Specialties Id",
  "Specialty",
  "Total Appointments",
  "Doctor's Qualification",
  "Experience Start Date",
  "About",
];
export const BrandHeadings = [
  "Name",
  "Logo",
  "Description",
  "Created At",
  "Edit",
  "Status",
  "Action",
  "Delete",
];
export const alerts = {
  LOADING: "Loading...",
  ARE_YOU_SURE: "Are You Sure?",
  YES: "yes",
  CANCEL: "cancel",
  SIGN_OUT: "Sign out"
}
export const SpecialityHeadings = [
  "Logo",
  "Name",
  "Status",
  "Activate / Deactivate",
];

export const CountryHeadings = [
  "Country",
  "Country Code",
  "DialCode",
  "Edit",
  "Status",
  "Action",
];

export const AppointmentStatus = {
  1: "Pending",
  2: "WatingForPayment",
  3: "Confirmed",
  4: "Rejected",
  5: "Expired",
  6: "Cancelled",
  7: "Failed",
  8: "Expired",
};

export const BadgeColor = {
  Pending: "warning",
  Confirmed: "success",
  Expired: "danger",
  Failed: "danger",
  Cancelled: "danger",
  WatingForPayment: "secondary",
  Rejected: "danger",
  Completed: "success",
  None: "secondary",
};

export const PaymentStatus = {
  1: "None",
  2: "Pending",
  3: "Failed",
  4: "Completed",
};

export const AppointmentStrings = {
  APPT_HEADING: "Appointments",
  HEADER_TEXT: "Appointment Details",
  APPT_ID: "Appointment Id",
  SLOT_START: "Slot Start Time",
  SLOT_END: "Slot End time",
  PAYMENT_STATUS: "Payment Status",
  BOOKING_TIME: "Booking Time",
  APPT_STATUS: "Appointment Status",
  PAYMENT_AMT: "Payment Amount",
  UPDATE_BTN: "Update",
  PAYMENT_DETAILS: "Payment Details",
  AMT: "Amount",
  CURRENCY: "Currency",
  METHOD: "Method",
  DESCRIPTION: "Description",
  CARD_ID: "Card Id",
  EMAIL: "Email",
  CONTACT: "Contact",
  DATE: "Date",
};

export const PatientStrings = {
  PATIENT_DETAILS: "Patient Details",
  PATIENT_HEADING: "Patient",
};

export const UserRole = {
  1: "Patient",
  2: "Admin",
  3: "Doctor",
  4: "CallCentreExecutive",
  5: "RCMExecutive",
  6: "DoctorAssistant",
};

export const SlotStatus = {
  1: "Open",
  2: "Locked",
  3: "Booked",
};

export const Gender = {
  1: "Male",
  2: "Female",
};

export const CallVia = {
  1: "Vidyo",
  2: "Jitsi",
  3: "Trova",
};

export const Currency = {
  INR: "INR",
  AED: "AED",
};

export const ConfigurationStrings = {
  HEADER_TEXT: "Configurations",
  BOOKING_TIME: "Booking TIme (in min)",
  PAYMENT_TIME: "Payment Complete Time (in min)",
  SLOT_TIME: "Slot Duration (in min)",
  DEACTIVATE_TIME: "Time to deactivate account (in days)"
};
export const DoctorStrings = {
  FIRST_NAME: "Name",
  LAST_NAME: "Last Name",
  PHONE_NUMBER: "Phone Number",
  EMAIL: "Email",
  DOB: "Date of Birth",
  CONSULT_FEE: "Consultation Fee",
  SPECIALISTAION: "Specialization",
  SELECT: "Select",
  PROFILE_IMAGE: "Profile Image",
  UPLOAD_IMAGE: "Upload Profile Image",
  CREATE_DOCTOR: "Create Doctor",
};

export const DoctorFields = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  EDUCATION: "medEducationName",
  EMAIL: "email",
  MOBNO: "mobileNumber",
  FEES: "consultationFee",
};

export const DoctorListStrings = {
  NOT_FOUND: "Not Found",
  DOCTOR_HEADER: "Doctors",
  CREATE_DOCTOR: "Create Doctor",
  DOCTOR_DETAILS: "Doctor Details",
  SLOTS: "Slots",
  SHIFT_DATE: "Shift Date",
  START_TIME: "Shift Start Time",
  END_TIME: "Shift End Time",
  SLOT_START_TIME: "Start Time",
  SLOT_END_TIME: "End Time",
  ACTION: "Action",
  DELETE_SLOTS: "Delete Slot",
  CREATE_SLOTS: "Create Slots",
  SHOW_SLOTS: "Show Slots",
  FORMAT: "YYYY-MM-DD",
};

export const BrandingStrings = {
  BRANDS_HEADER: "Brands",
  CREATE_BRAND: "Create Brand",
  BRAND_NAME: "Name",
  BRAND_LOGO: "Logo",
  UPLOAD_LOGO: "Upload Logo",
  DESCRIPTION: "Description",
  ACTIVATE_BTN: "Activate",
};

export const CountryStrings = {
  COUNTRIES_HEADER: "Countries",
  CREATE_COUNTRY: "Create Country",
  NAME: "Country Name",
  COUNTRY_CODE: "Country Code",
  DIAL_CODE: "Dial Code",
  CREATE: "Create",
  ACTIVATE_BTN: "Activate",
  UPDATE: "Update",
  NO_DATA: "No data to show",
  ACTIVE: "isActive",
  SUCCESS: "Success",
  VIEW1: "table",
  FORMAT: "hh:mm A DD-MM-YYYY",
  ISACTIVE1: "Active",
  ISACTIVE2: "Inactive",
  ID: ":id",
};

export const BADGE_STATUS = {
  Active: "success",
  Inactive: "danger",
};

export const TNC_STRINGS = {};

export const tncHeadings = ["Id", "Version", "Status", "Action", "View"];

export const Regex = {
  name1: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
  email: /^([a-zA-Z0-9_\-\.]+){2,4}@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
  mobno: /^[0-9]{10,10}$/,
  fees: /^\d+$/,
  countryCode: /^(\+?\d{1,3}|\d{1,4})$/,
  IMAGE: /\.(jpg|jpeg|png)$/i,
};

export const labels = {
  1: 'Very Dissatisfied',
  2: 'Dissatisfied',
  3: 'Neutral',
  4: 'Satisfied',
  5: 'Very Satisfied'
};

export const ValidationMessage = {
  REQUIRED: "This Field is Required",
  INVALID: "Invalid Format",
  MOB1: "Mobile no. cannot be negative",
  MOB2: "Must be 10 digits long",
  EXP_DATE: "Invalid Experience date",
  SPECIALITY: "Select speciality",
  PROFILE1: "Choose profile image",
  PROFILE2: "Unsupported format",
  DIAL: "Select Dial Code",
  PASSWORD: "Password must contain minimum 8 characters"
}

export const DELETE_REQUEST_STATUS = {
  0: 'Never Requested',
  1: 'Requested',
  2: 'In Process',
  3: 'Under Review',
  4: 'Completed',
}

export const DeleteRequestHeadings = [
  "User Name",
  "CreatedAt",
  "UpdatedAt",
  "Status",
  "Action",
  "Details"
]

export const DeleteButton = {
  1: "Acknowledge",
  2: "Review",
  3: "Complete"
}

export const DeleteColor = {
  1: "info",
  2: "secondary",
  3: "success",
}

export const DeleteBadge = {
  1: "danger",
  2: "info",
  3: "secondary",
  4: "success"
}

export const DeleteStrings = {
  Heading1: "Delete Requests",
  NO_DATA: "No Data",
  Heading: "Details",
  VIEW: "View",
  Name: "Name",
  CreatedAt: "Created At",
  ProcessedAt: "Processed At",
  ReviewedAt: "Reviewed At",
  CompletedAt: "Completed At"
}