import axios from "axios";
import { tokenExpireError, tokenExpire } from "./global";


axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    return tokenExpire(error)
  }
);
class Api {
  static get headers() {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-platform": "FlowersWeb",
      Authorization: `bearer ${localStorage.getItem("token")}`,
    };
  }

  static async get(route, params) {
    return Api.xhr({ route, params, method: "GET" });
  }

  static put(route, body, isFormtype, params) {
    return Api.xhr({ route, params, body, method: "PUT", isFormtype });
  }

  static async post(route, body, params) {
    return Api.xhr({ route, params, body, method: "POST" });
  }

  static async postDoctor(route, body, params) {
    return axios.post(route, body, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `bearer ${localStorage.getItem("token")}`,
      },
    });
  }

  static async updateBrand(route, body, params) {
    return axios.put(route, body, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `bearer ${localStorage.getItem("token")}`,
      },
    });
  }

  static delete(route, body, params) {
    return Api.xhr({ route, body, params, method: "DELETE" });
  }

  static async xhr({ route, params, body, method, isFormtype }) {
    const options = { method };
    if (params) {
      var queryString = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      route += "?" + queryString;
    }
    if (body) {
      options.body = JSON.stringify(body);
    }
    if (isFormtype) {
      options.headers = {
        "content-type": "multipart/form-data",
        Authorization: `bearer ${localStorage.getItem("token")}`,
      };
    } else {
      options.headers = Api.headers;
    }
    const response = await fetch(route, options);
    if (response.status === 401) {
      tokenExpireError();
    }
    else {
      return response.json();
    }
  }
}

export default Api;
