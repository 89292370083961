import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import { connect } from "react-redux";
import { routePath } from "./constants/routes";
import { parseJwt } from "./utils/global";
import { createBrowserHistory } from "history";

const history = new createBrowserHistory({ forceRefresh: true });

window.addEventListener('focus', () => {
  var DBOpenRequest = window.indexedDB.open("adminDb", 2);
  DBOpenRequest.onsuccess = function (event) {
    var db = event.target.result;
    var transaction = db.transaction(["admin"], "readwrite");
    var objectStore = transaction.objectStore("admin");
    var objectStoreRequest = objectStore.get(1);
    objectStoreRequest.onsuccess = function (event) {
      var myRecord = objectStoreRequest.result;
      if (myRecord.newNotif) {
        objectStore.put({ newNotif: false, id: 1 })
        history.push(window.location.href);
      }
    }
  }
});

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const Login = React.lazy(() => import("./views/Pages/Login"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const App = (props) => {
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const isTokenExpire = parseJwt(token);
      isTokenExpire && localStorage.clear();
    }
  }, []);
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route
            exact
            path={routePath.LOGIN}
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={(props) => <DefaultLayout {...props} />}
          />
          <Route
            exact
            path="/*"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state,
  };
};
export default connect(mapStateToProps)(App);
